import { makeApiRequest } from "@/src/lib/api-request";
import { useMutation } from "@tanstack/react-query"
import { useToken } from "@/src/store/token-store";
import { useUser } from "@/src/store/user-store";

interface EventData {
    event_id?: string
    user_id?: string | null
    creator_id?: string
    web_url?: string
}
interface AnalyticsMutation {
    event_type: string
    device_type: string
    event_data: EventData
}

export const useAnalyticsMutation = () => {
    const { user, _hasHydrated } = useUser()
    return useMutation({
        mutationKey: ['add-analytics'],
        mutationFn: async (props: AnalyticsMutation) => {
            await makeApiRequest({
                url:  (process.env.NEXT_PUBLIC_ANALYTICS_URL||'https://dev-kpi.lykstage.com')+'/v1/ingest',
                method: 'POST',
                data: {
                    event_type: props.event_type,
                    device_type: props.device_type,
                    event_data: props.event_data
                },
                sendCredentials: true,
                isAbsoluteUrl:true,
                isAuthenticated : user ? true : false,
                fallbackError: ''
            })
        }
    });
}
