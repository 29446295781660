'use client'
import { useEffect } from 'react'
import { usePushNotificationMutation } from '../mutations/push-notification/pushNotification';

const PushNotification = () => {
    const { mutate } = usePushNotificationMutation();
  useEffect(() => {
    let notiAvailed = localStorage.getItem("notiAvailed")
    if ('serviceWorker' in navigator && localStorage && notiAvailed !== "true") {
          mutate()
    }
  }, []);
    return <></>
}

export default PushNotification
