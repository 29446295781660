'use client'

import { useSrcTrackingMutation } from '@/src/mutations/tracking/src-tracking'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { memo, useEffect, useRef } from 'react'

const SrcTracking = () => {
  const searchParams = useSearchParams()
  const mutation = useSrcTrackingMutation()
  const router = useRouter()
  const pathname = usePathname()
  const firstTimeRef = useRef(false)
  useEffect(() => {
    if (
      searchParams?.get('src') !== null &&
      searchParams?.get('src') !== 'null' &&
      !firstTimeRef.current
    ) {
      const currentUrl = window.location.href
      const url = currentUrl.split('?')[0]
      mutation.mutate({
        src: searchParams?.get('src') || '',
        url: url,
      })
      firstTimeRef.current = true
    }
  }, [searchParams, mutation, pathname, router])

  return <></>
}

export default SrcTracking
