'use client'

import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'

const BannerLandingPage: React.FC<{ url: string }> = ({ url }) => {
  const pathname = usePathname()
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const isHome = pathname === '/home'
  const additionalStyles = isHome
    ? 'h-[200px] sm:h-[360px] rounded-[30px] mx-0 sm:mx-[16px]'
    : 'h-[600px]'
  const gradient = isHome
    ? 'rounded-[30px] bg-[linear-gradient(0deg,rgba(13,120,110,0.3),rgba(13,120,110,0.3)),linear-gradient(102.16deg,rgba(0,0,0,0.8)-232.34%,rgba(255,255,255,0.8)257.27%)]'
    : 'bg-gradient-radial-landing'

  const videoCss = isHome ? 'rounded-[30px]' : ''

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(err => {
        console.error("Video play failed:", err)
      })
    }
  }, [])

  return (
    <div className={`${additionalStyles} relative w-full`}>
      <video
        preload="metadata"
        ref={videoRef}
        className={`absolute inset-0 h-full w-full object-cover ${videoCss}`}
        loop
        playsInline
        muted
        src={url}
        data-src={url}
      />
      <div className={`${gradient} absolute inset-0 z-10`}></div>
    </div>
  )
}
export default BannerLandingPage
