'use client'
import { Button, buttonVariants } from '@/components/ui/button'
import Link from 'next/link'
import { ArrowRight } from 'lucide-react'

import { videoDataProps } from '@/data/landing-page/videoCardsData'
import { LoadingVideoCard, VideoCard } from '../ui/video-card'
import React from 'react'
import { Grid } from '@chakra-ui/react'
import { usePopularVideosQuery } from '@/src/queries/landing/popular-videos'

interface VideoShowcaseProps {
  videos: videoDataProps[]
}

export const VideoShowcase: React.FC<VideoShowcaseProps> = () => {
  const popularVideos = usePopularVideosQuery()

  let CarouselContent: React.ReactNode = null
  if (popularVideos.isLoading) {
    CarouselContent = (
      <>
        {Array(6)
          .fill('-')
          .map((_, i) => (
            <LoadingVideoCard key={i} />
          ))}
      </>
    )
  }

  if (popularVideos.isError) {
    CarouselContent = <p>Failed to load popular content</p>
  }

  if (popularVideos.data?.videos.length === 0) {
    return null
  }

  if (popularVideos.isSuccess) {
    CarouselContent = popularVideos.data?.videos.slice(0, 6).map((video) => (
      <>
          <VideoCard
            key={video.eventId}
            eventId={video.eventId}
            creatorId={video.channelId}
            videoDuration={video.duration}
            avatarUrl={video.imageUrl}
            creatorName={video.channelName}
            title={video.eventTitle}
            watchTime={video.UniqueWatchTime}
            thumbnailUrl={video.thumbnailImage}
            sharedImageName={video.sharedImageName || video.thumbnailImage}
            timelineUpload={video.eventEntryDate}
            themeColor={video.themeColor || '#E79051'}
            live={video.type == '2' ? 'live' : ''}
            isWatchList={video.isWatchList}
            encryptVideoUrl={video.encryptVideoUrl}
            isVerifiedTick={video.isVerifiedTick}
            watchCount={video.watchCount || '0'}
          />
      </>
    ))
  }

  return (
    <div className="flex flex-col items-center justify-center text-black">
      <h2
        className="max-w-[500px] px-4 text-center text-3xl font-bold leading-10 text-text-black"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        Explore trending content shared by creators
      </h2>
      <div
        className="mt-8 w-44 text-white"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <Button
          asChild
          style={{ borderRadius: '4px' }}
          className="flex tracking-wider"
        >
          <Link
            href="/home"
            className={buttonVariants({
              variant: 'primaryRed',
            })}
          >
            Discover More
            <span className="flex">
              <ArrowRight className="ml-2 h-4 w-4" />{' '}
            </span>
          </Link>
        </Button>
      </div>
      <div
        className="mt-8 w-full px-4 md:px-0"
        data-aos="zoom-in-up"
        data-aos-anchor-placement="top-bottom"
      >
        <Grid
          templateColumns={{
            base: '1fr',
            sm: '1fr',
            md: '1fr 1fr',
            lg: '1fr 1fr 1fr',
          }}
          columnGap={5}
          rowGap={5}
        >
          {CarouselContent}
        </Grid>
      </div>
    </div>
  )
}
