'use client'
import { Image, Text } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { IoCloseSharp } from 'react-icons/io5'

export const VideoRecap = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handlePlay = () => {
    if (videoRef.current) {
      if (!isPlaying) {
        videoRef.current.muted = false
        videoRef.current.src =
          'https://cdn.lykstage.com/videos/landing-video.mp4'
        videoRef.current.play()

        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen()
        } else if ((videoRef.current as any).webkitRequestFullscreen) {
          ;(videoRef.current as any).webkitRequestFullscreen()
        } else if ((videoRef.current as any).msRequestFullscreen) {
          ;(videoRef.current as any).msRequestFullscreen()
        }
      } else {
        videoRef.current.muted = true
      }
      setIsPlaying((prev) => !prev)
    }
  }

  const handleCancle = () => {
    if (videoRef.current) {
      videoRef.current.muted = true
      videoRef.current.pause()
      videoRef.current.src =
        'https://cdn.lykstage.com/videos/preview.mp4'
      videoRef.current.load()
      setIsPlaying(false)

      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if ((document as any).webkitExitFullscreen) {
        ;(document as any).webkitExitFullscreen()
      } else if ((document as any).msExitFullscreen) {
        ;(document as any).msExitFullscreen()
      }
    }
  }

  return (
    <>
      <div className="relative overflow-hidden" data-aos="fade">
        <div className="relative flex items-center justify-center">
          {isPlaying ? (
            <div className="relative h-[600px] w-full">
              <video
                ref={videoRef}
                className="absolute inset-0 h-full w-full object-cover"
                autoPlay
                loop
                preload="auto"
                playsInline
                controls
              >
                <source
                  src={
                    'https://cdn.lykstage.com/videos/landing-video.mp4'
                  }
                  type="video/mp4"
                />
              </video>
              <div className="absolute right-12 top-4 flex w-full justify-end">
                <div
                  className="w-fit rounded-full bg-[#000000] p-2.5"
                  onClick={handleCancle}
                >
                  <IoCloseSharp color="white" className="h-5 w-5" />
                </div>
              </div>
            </div>
          ) : (
            <div className="relative h-[600px] w-full">
              <video
                ref={videoRef}
                className="absolute inset-0 h-full w-full object-cover"
                autoPlay
                loop
                preload="auto"
                playsInline
                muted={!isPlaying}
              >
                <source
                  src={
                    'https://cdn.lykstage.com/videos/preview.mp4'
                  }
                  type="video/mp4"
                />
              </video>
              <div className="absolute inset-0 bg-[#000000] bg-opacity-50"></div>
            </div>
          )}

          {!isPlaying && (
            <div className="absolute z-20 flex flex-col items-center justify-center gap-2">
              <Image
                src={'/landing-page/play.svg'}
                alt="Play button"
                width={10}
                height={10}
                objectPosition="center"
                className="cursor-pointer"
                onClick={handlePlay}
              />
              <Text className="text-md font-semibold text-white">
                Play video
              </Text>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
