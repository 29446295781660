'use client'
import Link from 'next/link'
import { Button, buttonVariants } from '@/components/ui/button'
import { Icons } from '../icons'
import { ReactNode } from 'react'
import { useUser } from '@/src/store/user-store'

interface Section7Props {
  title: string
  description: ReactNode
  url: string
  btnText: string
}

export const Section7: React.FC<Section7Props> = ({
  title,
  description,
  url,
  btnText,
}) => {
  const { user } = useUser()
  return (
    <>
      <div className="flex w-full flex-col items-center justify-center gap-12 overflow-hidden px-4 py-10 md:px-16 lg:flex-row lg:px-16">
        <div className="flex w-full flex-col pr-0 sm:text-center lg:pr-12 lg:text-left">
          <h2
            className="pb-4 text-xl font-bold text-text-black sm:text-2xl md:text-3xl"
            data-aos="fade-right"
            data-aos-anchor-placement="top-bottom"
          >
            {title}
          </h2>
          <p
            className="text-md leading-6 text-text-black/80"
            data-aos="fade-right"
            data-aos-anchor-placement="top-bottom"
          >
            {description}
          </p>
          <div
            className="mx-auto mt-6 w-full sm:w-fit lg:mx-0 "
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <Button
              className="bg-primary w-full rounded-lg px-8 py-3 text-[16px] text-white xs:mt-5 sm:w-fit lg:mt-10 xxl:px-8 xxl:py-3"
              asChild
            >
              <Link
                href={user ? '/home' : '/auth/signup'}
                className={buttonVariants({
                  variant: 'primaryGreen',
                  size: 'lg',
                })}
              >
                {btnText}
              </Link>
            </Button>
          </div>
        </div>
        <div
          className="relative flex w-[80%] flex-shrink-0 items-center justify-center sm:w-auto lg:ml-6 lg:mt-0 lg:w-auto"
          data-aos="fade-left"
          data-aos-anchor-placement="top-bottom"
        >
          <video
            className="h-[19rem] w-full rounded-3xl object-cover object-top sm:h-[21rem] sm:w-[20rem]"
            autoPlay
            loop
            preload="auto"
            playsInline
            muted
          >
            <source src={url} type="video/mp4" />
          </video>
          <div className="section-2-green-effect absolute right-[-35px]  top-[-15px] flex h-[5rem] w-[5rem]  items-center justify-center rounded-full bg-green">
            <Icons.Camera className=" h-10 w-10 text-white" />
          </div>
          <div className="section-2-red-effect absolute  bottom-[30px] left-[-40px] flex h-[5rem] w-[5rem] items-center justify-center rounded-full bg-red">
            <Icons.Users className=" h-10 w-10 text-white" />
          </div>
        </div>
      </div>
    </>
  )
}
