import { makeApiRequest } from "@/src/lib/api-request";
import { useMutation } from "@tanstack/react-query";
import { useUser } from "@/src/store/user-store";
import { getDeviceType } from "@/src/lib/utils";

export const usePushNotificationMutation = () => {
  const { user } = useUser();
  const deviceType = getDeviceType();

  // Define the mutation function
  const mutation = useMutation({
    mutationKey: ['push-notification'],
    mutationFn: async () => {
      // Register the service worker
      console.log('ServiceWorker called');
      const register = await navigator.serviceWorker.register('/sw-notification.js');

      // Subscribe to push notifications
      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: 'BKbyoHJpyQJpLIChgML7dnfd0OXD1yYU7NZhfF5xCYH0Sa2DOi-AyLgCbSHmN0GiIUdOneQbQuuLOOvUr4_0zlc',
      });
      await localStorage.setItem('notiAvailed', 'true');
      // Make API request with the subscription data
      try{
        await makeApiRequest({
          url: 'https://notification.lykstage.com/subscribe',
          method: 'POST',
          data: {
            device_type: deviceType,
            sub_obj: subscription,
            env: process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' ? 'stage' : 'prod',
          },
          sendCredentials: true,
          isAbsoluteUrl: true,
          isAuthenticated : user ? true : false,
          fallbackError: 'noFallback',
        });
      }catch{
        localStorage.removeItem('notiAvailed');
      }
    }
  });

  return mutation;
};
