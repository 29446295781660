'use client'
import { useAnalyticsMutation } from '@/src/mutations/analytics/analytics'
import { useEffect, useRef } from 'react'

const Analytics = ({ eventType, videoData }: {
    eventType: string, videoData?: {
        event_id: string,
        creator_id: string,
        web_url: string
    }
}) => {
    const firstTimeRef = useRef(false)
    let device: string = '';
    if (typeof window !== "undefined") {
        let str = window.navigator?.userAgent;
        if (str.search("Android") !== -1) {
            device = "android-web"
        } else if (str.search("iPhone") !== -1) {
            device = "ios-web"
        } else {
            device = "web"
        }
    }
    const analytics = useAnalyticsMutation();

    useEffect(() => {

        if (device && !firstTimeRef.current && eventType === "video_details"&&videoData) {
            analytics.mutate({
                event_type: eventType,
                device_type: device,
                event_data: videoData
            })
            firstTimeRef.current = true
        }
        else if (device && !firstTimeRef.current) {
            analytics.mutate({
                event_type: eventType,
                device_type: device,
                event_data: {user_id:null}
            })
            firstTimeRef.current = true
        }
    }, [])
    return <></>
}

export default Analytics
