interface Faq {
  q: string
  a: string
}

export interface FaqsCardProps {
  faq: Faq
  idx: number
}

export const faqsList: Faq[] = [
  {
    q: 'How do rewards work?',
    a: "Watch Time Units(each unit is equal to 30 seconds): Every time you drop a video on LYKSTAGE, you're racking up Watch Time Units. Think of them as golden points for every second someone spends on your video. The cooler your content, the more you rack up!",
  },
  {
    q: 'Who gets what from the ad revenue?',
    a: 'Creators: You get a slice of the pie based on your video’s Watch Time Units. More engaging videos? Bigger slice for you! Viewers: Yup, even if you\'re just watching, you earn! It\'s our way to say, "Hey, your time’s precious, and we value it!"',
  },
  {
    q: 'Why is time more precious on LYKSTAGE for creators?',
    a: "Ever heard \"Time is Money\"? On LYKSTAGE, we're not just saying it; we're living it. Here's the deal: Unlike other places that pay you for fleeting 'Views', we value every second someone spends with your content. Why settle for earnings from a quick 30-second glance when you could be rewarded for every moment they’re engaged?",
  },
  {
    q: 'Want to cash out your LYKSTAGE earnings?',
    a: 'Head to the "Earnings & Rewards" on your home screen. Got 100 LYK coins or more? Awesome! Hit "Redeem Now" and get those coins transferred to your bank. Just double-check those bank details before you do!',
  },
  {
    q: 'What is the minimum video length requirement to be eligible for monetization?',
    a: 'Individual and business creators need a minimum video length of 3 minutes, which is equivalent to 6 watch-time units. However, for Business creators operating within the Media and Entertainment industry, a shorter video length of just 30 seconds, which is equivalent to 1 watch-time unit, is sufficient to qualify for monetization.',
  },
]
