'use client'
import { Flex, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'

const AnimatedCount = () => {
  const [count, setCount] = useState(0)
  const [fade, setFade] = useState(1)
  const countRef = useRef(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const end = 1000000
    const duration = 20
    const incrementTime = 50
    const steps = Math.ceil((duration * 1000) / incrementTime)
    const step = Math.ceil(end / steps)

    const animateCount = () => {
      intervalRef.current = setInterval(() => {
        countRef.current += step
        if (countRef.current >= end && fade === 1) {
          countRef.current = end
          setCount(countRef.current)
          timeoutRef.current = setTimeout(() => {
            setCount(0)
            countRef.current = 0
            animateCount()

          }, 3000)
          if (intervalRef.current) clearInterval(intervalRef.current)
          timeoutRef.current = setTimeout(() => {
            setFade(0)
          }, 2500)
          timeoutRef.current = setTimeout(() => {
            setFade(1)
          }, 3000)
        } else {
          setCount(countRef.current)
        }
      }, incrementTime)
    }

    animateCount()

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <Flex
      sx={{
        opacity: fade,
        transition: 'opacity 0.5s ease-in-out',
      }}
    >
      <Image
        src={'/landing-page/whiteStar.svg'}
        alt="Hero Image"
        className="hidden h-2 w-2 sm:block sm:h-6 sm:w-8"
      />

      <Text>
        <span style={{
          fontFamily: 'sans-serif!important',
          fontWeight: 700,
          fontSize: 18,
          marginRight: 5
        }}>{count.toLocaleString()}</span>
        incredible creators and viewers joined the community</Text>
    </Flex>
  )
}

export default AnimatedCount
