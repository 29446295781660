'use client'

import { useRef, useState } from 'react'

import { faqsList, FaqsCardProps } from '@/data/landing-page/faqData'
import { Icons } from '../icons'
import { Button, buttonVariants } from '../ui/button'
import Link from 'next/link'

const FaqsCard: React.FC<FaqsCardProps> = ({ faq, idx }) => {
  const answerElRef = useRef<HTMLDivElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleOpenAnswer = () => {
    setIsExpanded(!isExpanded)
  }

  const answerStyles: React.CSSProperties = {
    height: isExpanded ? `${answerElRef.current?.scrollHeight}px` : '0px',
  }

  return (
    <div
      className="mt-5 overflow-hidden rounded-xl border border-textboxborder bg-lightergray px-6 pt-4"
      key={idx}
      onClick={handleOpenAnswer}
    >
      <h4
        className="flex cursor-pointer items-center justify-between pb-5 font-medium text-black"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="text-md max-w-[90%] xs:mr-2 md:pr-0 md:text-lg">
          {faq.q}
        </div>
        {isExpanded ? (
          <Icons.ChevronUp className="text-gray-500 ml-2 h-5 w-5 transition-all transition-transform duration-300 lg:h-8 lg:w-8" />
        ) : (
          <Icons.ChevronDown className="text-gray-500 ml- h-5 w-5 transition-transform lg:h-8 lg:w-8" />
        )}
      </h4>
      <div
        ref={answerElRef}
        className="overflow-hidden transition-all duration-300"
        style={answerStyles}
      >
        <div>
          <p className="text-gray-500 pb-4 pr-8">{faq.a}</p>
        </div>
      </div>
    </div>
  )
}

export const Faqs: React.FC = () => {
  return (
    <section className="mx-auto mt-12 max-w-screen-xl px-4 leading-relaxed text-black md:px-8">
      <div className="space-y-3 text-center">
        <h2
          className="text-gray-800 px-8 text-3xl font-semibold leading-10 md:px-2"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          Frequently Asked Questions
        </h2>
      </div>
      <div className="mx-auto mt-14 max-w-[860px]">
        {faqsList.map((faq, idx) => (
          <FaqsCard key={idx} idx={idx} faq={faq} />
        ))}
      </div>

      <div className="flex flex-col justify-center items-center">
        <h1 className="text-gray-800 mt-10 px-8 text-center text-2xl font-semibold leading-10 md:px-2">
          Got more questions?
        </h1>
        <Button
          className="bg-primary w-fit rounded-lg px-8 py-3 text-[16px] text-white mt-4 xxl:px-8 xxl:py-3"
          asChild
          style={{ borderRadius: '4px' }}
        >
          <Link
            href="/faqs"
            className={buttonVariants({
              variant: 'primaryGreen',
              size: 'lg',
            })}
          >
            Browse all FAQs
          </Link>
        </Button>
      </div>
    </section>
  )
}

export default Faqs
