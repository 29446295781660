'use client'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import Link from 'next/link'
import Image from 'next/legacy/image'
import { FooterDataProps, footerData } from '@/data/landing-page/footerData'
import { Icons } from '../icons'
import { useNewsletterMutation } from '@/src/mutations/landing/newsletter'
import { toast } from 'sonner'
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { useState } from 'react'
interface NewsLetterTs {
  email: string
}
export default function Footer() {
  const currentYear = new Date().getFullYear()
  const methods = useForm<NewsLetterTs>()
  const [email, setEmail] = useState('')
  const [isSubmitDone, setSubmitDone] = useState(false)
  const newsletterMutation = useNewsletterMutation()
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = methods

  const onSubmit = (data: NewsLetterTs) => {
    newsletterMutation.mutate(data, {
      onSuccess: () => {
        toast.success(
          'You have successfully joined our mailing list. Stay tuned for exciting updates!'
        )
        reset()
        setEmail('')
        setSubmitDone(true)
        setTimeout(() => {
          setSubmitDone(false)
        }, 5000)
      },
      onError: (error) => {
        toast.error('Failed to Join')
        setError('root', {
          type: 'manual',
          message: error.message,
        })
      },
    })
  }

  return (
    <div className="h-fit w-full bg-black py-8">
      <div className="mb-0 ml-auto mr-auto mt-0 flex justify-center p-4 sm:container xs:flex-col sm:gap-8 md:flex-row md:items-center md:gap-0">
        <div className="h-fit w-full bg-black">
          <div className="flex justify-between xs:flex-col xs:p-1 sm:gap-8 md:flex-row md:items-start md:gap-0 md:p-4">
            <div
              className="flex flex-col gap-6"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="relative mx-auto h-[50px] w-[188px] text-lg font-semibold md:mx-0">
                <Link href="/home">
                  <Image src={`/lykstage-logo.svg`} alt="logo" layout="fill" />
                </Link>
              </div>
              <div className="flex flex-col gap-4 text-sm text-white">
                <div className="text-center md:text-left">
                  {footerData.description}
                </div>
                <div className="text-center md:text-left">
                  {footerData.mailingListLabel}
                </div>

                {!isSubmitDone ? (
                  <FormProvider {...methods}>
                    <form name="newsletter" onSubmit={handleSubmit(onSubmit)}>
                      <FormControl
                        isRequired
                        isInvalid={!!errors.email}
                        display={'block'}
                      >
                        <div className="relative flex items-center gap-2 xs:mb-5 md:mb-0">
                          <Input
                            id="emailNewsletter"
                            {...register('email', {
                              required: 'Email is required',
                            })}
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Your Email"
                            className="min-w-[160px] rounded-md border-bordergray bg-white py-2 pl-8 pr-2 text-black md:min-w-[240px]"
                          />
                          <Icons.Email className="absolute left-3 h-[13px] w-[13px] text-bordergray" />

                          <Button
                            type="submit"
                            isLoading={newsletterMutation.isPending}
                            className="rounded-md bg-red px-8 py-3 text-white transition-all duration-300 xs:text-xs md:text-[13px] xxl:px-8 xxl:py-3"
                          >
                            Submit
                          </Button>
                        </div>
                        <FormErrorMessage>
                          <>{errors.email && errors.email?.message}</>
                        </FormErrorMessage>
                      </FormControl>
                    </form>
                  </FormProvider>
                ) : (
                  <Flex className="max-sm:justify-center">
                    <Button className="mr-1 w-fit rounded-md bg-green py-3 pl-6 pr-4 text-white transition-all duration-300 xs:text-xs md:text-[13px] xxl:px-8 xxl:py-3">
                      Subscribed
                      <Image
                        src="/landing-page/check-small.svg"
                        alt="check"
                        width={24}
                        height={24}
                      />
                    </Button>
                  </Flex>
                )}
              </div>
            </div>
            <div className="mx-0 mt-4 flex flex-row justify-between pr-0 xs:mb-5 xs:w-[100%] sm:pl-8 md:mb-0 md:mt-0 md:w-[80%] md:pl-12 lg:pr-8 xl:w-[50%]">
              {footerData.sections.map((section, idx) => (
                <div
                  key={idx}
                  className="flex flex-col gap-2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  <span className="pb-4 pr-1 text-sm font-bold text-white lg:text-lg lg:font-semibold">
                    {section.label}
                  </span>
                  <div className="flex flex-col text-sm text-white visited:text-white xs:gap-2 md:gap-4">
                    {section.items.map((item, idx) => (
                      <Link
                        key={idx}
                        href={item.href}
                        className="transition-all duration-300 visited:text-white hover:text-green"
                        target={item.target ? item.target : '_self'}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="text-white sm:p-4">
            &#169; {footerData.brand} {currentYear}
            <h5 className="mt-2 text-sm">
              LYKSTAGE copyright and intellectual property are owned by LYK Inc,
              a Delaware-based corporation.
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}
