'use client'
import Link from 'next/link'
import BannerLandingPage from './banner'
import { Button, buttonVariants } from '@/components/ui/button'
import Image from 'next/legacy/image'
import { Navbar } from './navbar'
import { Suspense, useState } from 'react'
import { Sidebar } from './sidebar'

export const HeroPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev)
  }
  const closeSidebarOnly = () => {
    setIsSidebarOpen(false)
  }
  return (
    <>
      <Sidebar isSideBarOpen={isSidebarOpen} closeSidebar={toggleSidebar} closeSidebarOnly={closeSidebarOnly} />
      <div className="relative overflow-hidden" data-aos="fade">
        <Suspense>
          <Navbar isTransparent toggleSidebar={toggleSidebar} />
        </Suspense>
        <div className="relative">
          <BannerLandingPage url="https://cdn.lykstage.com/videos/bg-video.mp4" />
          <Image
            src={'/landing-page/gradient-effect.png'}
            alt="gradient effect image"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            loading="eager"
          />
        </div>
        <div className="absolute inset-0 z-10 mx-auto mt-[280px] flex max-w-[754px] flex-col px-4 text-center text-white md:mt-[280px] md:px-8 ">
          <h2
            className="mb-4 text-2xl font-bold sm:text-3xl md:mb-10 md:text-4xl"
          // data-aos="fade-up"
          // data-aos-anchor-placement="top-bottom"
          >
            Discover and create amazing content. Get&nbsp;
            <span className="text-primary-green">rewarded</span> for your watch
            time.
          </h2>
          <p
            className="mb-4 px-2 text-sm font-semibold xs:leading-6 sm:text-lg md:px-16"
          // data-aos="fade-up"
          // data-aos-anchor-placement="top-bottom"
          >
            Video Sharing Platform that serves not only video content creators but also the viewers.
          </p>
          <div
            className="mt-4 flex items-center justify-center"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <Button asChild style={{ borderRadius: '4px' }}>
              <Link
                href="/home"
                className={buttonVariants({
                  variant: 'primaryGreen',
                })}
              >
                Start Watching
              </Link>
            </Button>
            <Link
              className="ml-5 cursor-pointer transition-all duration-300 hover:text-primary-green hover:underline"
              data-aos="fade-up"
              href="#learn-more"
              data-aos-anchor-placement="top-bottom"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
