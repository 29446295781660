interface FooterNavItem {
  href: string
  name: string
  target?: string
}

interface FooterSection {
  label: string
  items: FooterNavItem[]
}

export interface FooterDataProps {
  logoUrl: string
  description: string
  mailingListLabel: string
  sections: FooterSection[]
  brand: string
}

export const footerData: FooterDataProps = {
  logoUrl: 'lykstage-logo',
  description: 'Watch. Create. Earn. Repeat.',
  mailingListLabel: 'Join our mailing list for the latest updates',
  sections: [
    {
      label: 'Useful Links',
      items: [
        { href: '/auth/login', name: 'Login' },
        { href: '/how-earnings-work', name: 'Rewards' },
        { href: '/faqs', name: 'FAQs' },
      ],
    },
    {
      label: 'Social Links',
      items: [
        { href: 'https://x.com/lyk_stage', name: 'Twitter' },
        { href: 'https://www.linkedin.com/company/lykapp/', name: 'LinkedIn' },
        {
          href: 'https://www.instagram.com/lykstage/',
          name: 'Instagram',
        },
        { href: 'https://www.facebook.com/Lykstage/', name: 'Facebook' },
      ],
    },
    {
      label: 'Legal',
      items: [
        { href: '/termsofservice', name: 'Terms of Service', target: '_blank' },
        { href: '/privacypolicy', name: 'Privacy Policy', target: '_blank' },
        { href: '/community', name: 'Community Guidelines', target: '_blank' },
      ],
    },
  ],
  brand: 'LYKSTAGE',
}
