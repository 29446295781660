import { makeApiRequest } from '@/src/lib/api-request'
import { useMutation } from '@tanstack/react-query'
import { useUser } from '@/src/store/user-store'
interface Data {
  url: string
  src: string
}

export const useSrcTrackingMutation = () => {
  const { user } = useUser()
  return useMutation({
    mutationFn: async (props: Data) => {
      await makeApiRequest({
        url: 'VideoDetails/promoLinkClick',
        method: 'POST',
        data: {
          countryCode: user?.countryCode || '',
          phone: user?.phoneNumber || '',
          url: props.url,
          email: user?.email || '',
          src: props.src,
          phpSessionId: '',
        },
        isAuthenticated: false,
        fallbackError: 'Failed to track src',
      })
    },
  })
}
